import React, { useState, useEffect } from 'react';

const LoadingSpinner = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 1;
      });
    }, 30); // Adjust the speed of the progress bar here

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="spinner-container">
      <div className="spinner"></div>
      <p>{progress}%</p>
    </div>
  );
};

export default LoadingSpinner;
