import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import { CartProvider } from './context/CartContext';
import LoadingSpinner from './components/LoadingSpinner'; // Import a loading spinner component
import Ordersuccessful from './pages/Ordersuccessful';

const About = lazy(() => import('./pages/About'));
const Contact = lazy(() => import('./pages/Contact'));
const Cart = lazy(() => import('./pages/Cart'));
const Homepage = lazy(() => import('./pages/Homepage'));
const Viewproduct = lazy(() => import('./pages/Viewproduct'));
const Uniforms = lazy(() => import('./pages/Uniforms'));
const SchoolStationery = lazy(() => import('./pages/SchoolStationery'));
const OfficeStationery = lazy(() => import('./pages/OfficeStationery'));
const Textbooks = lazy(() => import('./pages/Textbooks'));
const Services = lazy(() => import('./pages/Services'));

function App() {
  return (
    <CartProvider>
      <Router>
        <div>
          <Navbar />
          <div>
            <Suspense fallback={<LoadingSpinner />}>
              <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/viewproduct/:id" element={<Viewproduct />} />
                <Route path="/school-uniforms" element={<Uniforms />} />
                <Route path="/school-stationery" element={<SchoolStationery />} />
                <Route path="/office-stationery" element={<OfficeStationery />} />
                <Route path="/textbooks" element={<Textbooks />} />
                <Route path="/services" element={<Services />} />
                <Route path="/ordersuccessful" element={<Ordersuccessful />} />
              </Routes>
            </Suspense>
          </div>
        </div>
      </Router>
    </CartProvider>
  );
}

export default App;
