import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="bg-white shadow p-4 flex justify-between items-center sticky top-0 z-10">
      <div className="flex items-center">
        <img src={logo} alt="Logo" className="h-10 mr-4" />
        <div className="flex flex-col font-bold text-[#888888]">
          <p>SCHOOLRITE</p>
          <p>STATIONERY</p>
        </div>
      </div>
      <div className="hidden md:flex space-x-4">
        <Link to="/" className="text-gray-700 hover:text-[#7F0741]">Home</Link>
        <Link to="/about" className="text-gray-700 hover:text-[#7F0741]">About Us</Link>
        <Link to="/contact" className="text-gray-700 hover:text-[#7F0741]">Contact</Link>
      </div>
      <div className="relative">
        <Link to="/cart" className="text-[#7F0741] font-bold flex items-center">
          CART
        </Link>
      </div>
      <div className="md:hidden flex items-center">
        <button onClick={toggleMenu} className="text-[#7F0741]">
          &#9776;
        </button>
      </div>
      {isOpen && (
        <div className="md:hidden absolute top-16 left-0 w-1/2 bg-white shadow-lg">
          <button onClick={closeMenu} className="text-[#7F0741] absolute top-0 right-0 p-4">
            &times;
          </button>
          <Link to="/" className="block px-4 py-2 text-gray-700 hover:bg-gray-200" onClick={closeMenu}>Home</Link>
          <Link to="/about" className="block px-4 py-2 text-gray-700 hover:bg-gray-200" onClick={closeMenu}>About Us</Link>
          <Link to="/contact" className="block px-4 py-2 text-gray-700 hover:bg-gray-200" onClick={closeMenu}>Contact</Link>
          <Link to="/school-stationery" className="block px-4 py-2 text-gray-700 hover:bg-gray-200" onClick={closeMenu}>School Stationery</Link>
          <Link to="/office-stationery" className="block px-4 py-2 text-gray-700 hover:bg-gray-200" onClick={closeMenu}>Office Stationery</Link>
          <Link to="/textbooks" className="block px-4 py-2 text-gray-700 hover:bg-gray-200" onClick={closeMenu}>Textbooks</Link>
          <Link to="/school-uniforms" className="block px-4 py-2 text-gray-700 hover:bg-gray-200" onClick={closeMenu}>School Uniforms</Link>
          <Link to="/services" className="block px-4 py-2 text-gray-700 hover:bg-gray-200" onClick={closeMenu}>Services</Link>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
