import React from 'react';

function Ordersuccessful() {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center">
        <h1 className="text-2xl font-bold text-green-600 mb-4">Order Status</h1>
        <p className="text-gray-700">Your order has been initiated. We will be in touch with you once we verify your payment.</p>
      </div>
    </div>
  );
}

export default Ordersuccessful;
